import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    byRequest: 'employer.resumesSearch.nigmer.byRequest',
    willFind: 'employer.resumesSearch.nigmer.willFind',
};

const QuerySuggests: TranslatedComponent = ({ trls }) => {
    const suggests = useSelector((state) => state.resumeSearchResult?.suggests?.text);

    if (!suggests) {
        return null;
    }

    return (
        <p>
            {trls[TrlKeys.byRequest]}
            {NON_BREAKING_SPACE}
            <BlokoLink disableVisited Element={Link} to={`?${suggests.query}`}>
                <strong>{suggests.value}</strong>
            </BlokoLink>
            {NON_BREAKING_SPACE}
            {format(trls[TrlKeys.willFind], { '{0}': suggests.found })}
        </p>
    );
};

export default translation(QuerySuggests);
