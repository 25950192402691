import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Card, Text, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import { H3Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import capitalize from 'bloko/common/capitalize';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SearchType from 'src/components/NovaFilters/SearchType';
import Rating, { RatingValue } from 'src/components/TempMagritteRating';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import styles from './searchRating.less';

const SEARCH_RATING = '/notices/mark_as_viewed';

declare global {
    interface FetcherPostApi {
        [SEARCH_RATING]: {
            queryParams: null;
            body: { name: string };
            response: void;
        };
    }
}

const TrlKeys = {
    title: 'vacancy.search.rating.title',
    thnx: 'vacancy.search.rating.thnxMessage',
    smiles: {
        sad: 'vacancy.search.rating.sad',
        unhappy: 'vacancy.search.rating.unhappy',
        indifirent: 'vacancy.search.rating.indifirent',
        smirk: 'vacancy.search.rating.smirk',
        happy: 'vacancy.search.rating.happy',
    },
};

enum Smiles {
    Sad = 'sad',
    Unhappy = 'unhappy',
    Indifirent = 'indifirent',
    Smirk = 'smirk',
    Happy = 'happy',
}

interface SearchRatingProps {
    portfolio: string;
    eventName: string;
    infoTipName: string;
}

const MAP_SMILE_VALUE = {
    [RatingValue.Sad]: Smiles.Sad,
    [RatingValue.Halfsad]: Smiles.Unhappy,
    [RatingValue.Neutral]: Smiles.Indifirent,
    [RatingValue.Halfsmile]: Smiles.Smirk,
    [RatingValue.Smile]: Smiles.Happy,
};

const SearchRating: TranslatedComponent<SearchRatingProps> = ({ trls, portfolio, eventName, infoTipName }) => {
    const isResumeSearch = useSelector((state) => state.searchClustersSettings.type) === SearchType.Resume;
    const searchRating = useSelector((state) => state.searchRating);
    const spyElement = useRef(null);
    const [clicked, setClicked] = useState<Smiles | null>(null);

    const isMagritte = useMagritte();

    const clickEvent = (value: Smiles) => {
        if (clicked === null) {
            Analytics.sendHHEventButtonClick(eventName, {
                portfolio,
                requestUrl: window.location.href,
                score: value,
            });
            fetcher
                .post(SEARCH_RATING, {
                    name: infoTipName,
                })
                .then(() => {
                    setClicked(value);
                })
                .catch(console.error);
        }
    };

    useEffect(() => {
        if (spyElement.current) {
            Analytics.sendHHEventElementShown(spyElement.current, {
                name: 'select_search_rating_shown',
                portfolio: 'PORTFOLIO-14535',
                requestUrl: window.location.href,
            });
        }
    }, [spyElement]);

    if (isMagritte && searchRating) {
        return (
            <>
                <Card borderRadius={16} padding={16} showBorder stretched ref={spyElement}>
                    <Text typography="title-5-semibold">{trls[TrlKeys.title]}</Text>
                    <MagritteVSpacing default={16} />
                    <div className={classnames({ [styles.ratingInactive]: !!clicked })}>
                        {/* TODO: заменить на компонент магритта в https://jira.hh.ru/browse/PORTFOLIO-31847 */}
                        <Rating
                            name="search-rating"
                            onChange={(rating) => {
                                clickEvent(MAP_SMILE_VALUE[rating]);
                            }}
                        />
                    </div>
                </Card>
                <MagritteVSpacing default={12} />
            </>
        );
    }

    return searchRating ? (
        <>
            <div
                className={classnames(styles.smilesWrapper, {
                    [styles.smilesWrapperResumeSearch]: isResumeSearch,
                })}
                ref={spyElement}
            >
                <>
                    <H3Section>{trls[TrlKeys.title]}</H3Section>
                    <VSpacing base={4} />
                    <div className={styles.smiles}>
                        {Object.values(Smiles).map((smile) => {
                            return (
                                <button
                                    key={smile}
                                    aria-label={trls[TrlKeys.smiles[smile]]}
                                    className={classnames(styles.smile, {
                                        [styles.smileInactive]: smile !== clicked && clicked !== null,
                                        [styles.smileActive]: smile === clicked,
                                    })}
                                    onClick={() => clickEvent(smile)}
                                >
                                    <span
                                        className={classnames(
                                            styles.smileIcon,
                                            styles[`smileIcon${capitalize(smile)}`]
                                        )}
                                    />
                                </button>
                            );
                        })}
                    </div>
                </>
            </div>
            <VSpacing base={4} />
        </>
    ) : null;
};

export default translation(SearchRating);
