import { useEffect, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import SearchType from 'src/components/NovaFilters/SearchType';
import useSendFilterForm from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    apply: 'search.filters.apply',
    foundOne: 'novaFilters.vacancies.found.one',
    foundSome: 'novaFilters.vacancies.found.some',
    foundMany: 'novaFilters.vacancies.found.many',
    foundZero: 'vacancySearch.title.found.zero',
};

const CounterButton: TranslatedComponent = ({ trls }) => {
    const counts = useSelector((state) => state.searchCounts);
    const [isLoadFilters, setIsLoadFilters] = useState(false);
    const searchType = useSelector((state) => state.searchClustersSettings.type);

    const sendFilterForm = useSendFilterForm();

    useEffect(() => {
        setIsLoadFilters(counts.isLoad);
    }, [counts]);

    return (
        <Button
            mode="primary"
            style="accent"
            size="large"
            onClick={() => {
                setIsLoadFilters(true);
                sendFilterForm();
                Analytics.sendHHEventButtonClick(
                    `${
                        searchType === SearchType.EmployerVacancy ? SearchType.Vacancy : searchType
                    }_search_filter_apply_all`,
                    {
                        hhtmSource:
                            searchType === SearchType.EmployerVacancy
                                ? 'employer_page_vacancy_search_filter'
                                : `${searchType}_search_filter`,
                    }
                );
            }}
            loading={isLoadFilters}
            stretched
        >
            {searchType === SearchType.Resume ? (
                trls[TrlKeys.apply]
            ) : (
                <Conversion
                    value={counts.value}
                    zero={trls[TrlKeys.foundZero]}
                    one={trls[TrlKeys.foundOne]}
                    some={trls[TrlKeys.foundSome]}
                    many={trls[TrlKeys.foundMany]}
                    hasValue={false}
                    format={(trl) =>
                        format(trl, {
                            '{0}': numberFormatter.format(String(counts.value), {
                                groupSeparator: NON_BREAKING_SPACE,
                            }),
                        })
                    }
                />
            )}
        </Button>
    );
};

export default translation(CounterButton);
