import { useMemo } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import CatalogLinks from 'src/components/CatalogSearch/CatalogLinks';
import { CatalogLink } from 'src/components/CatalogSearch/CatalogLinksList';

const TrlKeys = {
    header: 'search.catalogLinks.title',
};

interface CatalogSearchProps {
    queries: CatalogLink[];
}

const CatalogSearch: TranslatedComponent<CatalogSearchProps> = ({ queries, trls }) => {
    const links = useMemo(
        () =>
            queries.map((query) => {
                return { ...query, name: query.path.split('/').slice(2).join('/') };
            }),
        [queries]
    );
    return <CatalogLinks links={links} title={trls[TrlKeys.header]} hhtmSourceLabel={'BOTTOM_SIMILAR_QUERY'} />;
};

export default translation(CatalogSearch);
