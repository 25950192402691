import { FC } from 'react';

import SearchType from 'src/components/NovaFilters/SearchType';
import ScrollToTop from 'src/components/ScrollToTop';
import { useSelector } from 'src/hooks/useSelector';

const VacancySearchScrollToTop: FC = () => {
    const totalSnippets = useSelector((state) => state.vacancySearchResult.vacancies.length);
    const isMobileFiltersShown = useSelector((state) => state.searchPreference.isShown);

    return (
        <ScrollToTop
            analyticsParams={{ searchType: SearchType.Vacancy, totalSnippets }}
            forceHidden={isMobileFiltersShown}
        />
    );
};

export default VacancySearchScrollToTop;
