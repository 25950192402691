import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import pickVacancyFromHeaderButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/search/pick_vacancy_from_header_button_click';
import HSpacing from 'bloko/blocks/hSpacing';
import { H3Section } from 'bloko/blocks/header';
import Link from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useContextVacancyIdFromUrl } from 'src/components/ResumeSearch/PickVacancyModal/useContextVacancyIdFromUrl';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { showPickVacancyModal } from 'src/models/pickVacancyOnResumeSearch';

const TrlKeys = {
    toVacancy: 'resumeSearch.pickVacancyHeader.toVacancy',
    pickVacancy: 'resumeSearch.pickVacancyHeader.pickVacancy',
    pick: 'treeselector.save',
    change: 'jobSearchStatus.change',
};

const PickVacancyAdditionalHeader: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();

    const managerVacancies = useSelector((state) => state.managerVacancies);
    const vacancies =
        managerVacancies.vacanciesGroups?.vacanciesGroupList.flatMap(
            (vacanciesGroup) => vacanciesGroup.vacanciesList
        ) || [];
    const activeVacanciesCount =
        useSelector((state) => state.managerVacancies.vacanciesGroups?.activeVacanciesCount) || 0;
    const contextVacancyId = useContextVacancyIdFromUrl();
    const vacancyName = vacancies?.find((vacancy) => vacancy.vacancyId === contextVacancyId)?.name;

    const text = vacancyName ? `${trls[TrlKeys.toVacancy]} "${vacancyName}"` : trls[TrlKeys.pickVacancy];
    const linkText = vacancyName ? trls[TrlKeys.change] : trls[TrlKeys.pick];

    const openModal = useCallback(() => {
        pickVacancyFromHeaderButtonClick({ activeVacanciesCount });
        dispatch(showPickVacancyModal(true));
    }, [activeVacanciesCount, dispatch]);

    return (
        <div>
            <H3Section Element="span">{text}</H3Section>
            <HSpacing base={2} />
            <Link onClick={openModal}>{linkText}</Link>
        </div>
    );
};

export default translation(PickVacancyAdditionalHeader);
