import Analytics from '@hh.ru/analytics-js';
import { H3Section } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { ElementSpyInstance } from 'bloko/common/elementSpy';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';

import styles from './NotFoundByContactBanner.less';

const TrlKeys = {
    title: 'search.resume.notFoundByContactTitle',
    description: 'search.resume.notFoundByContactDescription',
};

const sendElementShownAnalytics = (element: HTMLElement): ElementSpyInstance => {
    return Analytics.sendHHEventElementShown(element, {
        name: 'not_found_by_contact_banner',
    });
};

const NotFoundByContactBanner: TranslatedComponent = ({ trls }) => {
    return (
        <ElementShownAnchor fn={sendElementShownAnalytics} className={styles.banner}>
            <div className={styles.bannerInfo}>
                <H3Section>{trls[TrlKeys.title]}</H3Section>
                <VSpacing base={2} />
                <Text>{trls[TrlKeys.description]}</Text>
            </div>
            <div className={styles.bannerImage}></div>
        </ElementShownAnchor>
    );
};

export default translation(NotFoundByContactBanner);
