import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    corrected: 'employer.resumesSearch.nigmer.corrected',
    nothing: 'employer.resumesSearch.nigmer.nothing',
};

const QueryFixer: TranslatedComponent = ({ trls }) => {
    const queryFix = useSelector((state) => state.resumeSearchResult?.fixes?.text?.original);

    if (!queryFix) {
        return null;
    }

    return (
        <p>
            {trls[TrlKeys.corrected]}
            <span className="resumesearch__request">{queryFix}</span>
            {trls[TrlKeys.nothing]}
        </p>
    );
};

export default translation(QueryFixer);
