import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import CatalogLinks from 'src/components/CatalogSearch/CatalogLinks';

const TrlKeys = {
    header: 'seo_catalog.extra_filters.title',
};

const FILTERS_MIN_COUNT = 4;

const ExtraFilters: TranslatedComponent = ({ trls }) => {
    const extraFilters = useSelector((state) => state.extraFilters);

    if (extraFilters.length < FILTERS_MIN_COUNT) {
        return null;
    }

    return <CatalogLinks links={extraFilters} title={trls[TrlKeys.header]} hhtmSourceLabel={'BOTTOM_FILTER_CATALOG'} />;
};

export default translation(ExtraFilters);
