import { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import pickedVacancyInModalButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/search/picked_vacancy_in_modal_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import InputText from 'bloko/blocks/inputText';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import Modal, { ModalHeader, ModalTitle, ModalFooter, ModalContent } from 'bloko/blocks/modal';
import Radio from 'bloko/blocks/radio';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import debounce from 'bloko/common/debounce';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import searchManagerVacancies from 'src/components/EmployerVacancies/searchManagerVacancies';
import FilterIcon from 'src/components/FilterIcon';
import Form from 'src/components/Form';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { showPickVacancyModal } from 'src/models/pickVacancyOnResumeSearch';

import {
    CONTEXT_VACANCY_ID_NOT_CHOSEN,
    CONTEXT_VACANCY_ID_PARAM,
    useContextVacancyIdFromUrl,
} from 'src/components/ResumeSearch/PickVacancyModal/useContextVacancyIdFromUrl';

import style from './style.less';

const FILTER_DEBOUNCE = 300;

const TrlKeys = {
    myVacancies: 'vacancies.groups.myVacancies',
    notChosen: 'employer.vacancy.SelectInputField.defaultEmptyText',
    submit: 'customSelect.submit',
    cancel: 'assessments.filters.cancel',
    search: 'index.employerLastSearches.search',
};
const PickVacancyModal: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { isEnablePickVacancy, isShowModal } = useSelector((state) => state.pickVacancyOnResumeSearch);
    const managerVacancies = useSelector((state) => state.managerVacancies);
    const vacancies =
        managerVacancies.vacanciesGroups?.vacanciesGroupList.flatMap(
            (vacanciesGroup) => vacanciesGroup.vacanciesList
        ) || [];
    const displayedVacancyList = [
        { name: trls[TrlKeys.notChosen], vacancyId: CONTEXT_VACANCY_ID_NOT_CHOSEN, areaName: null },
        ...vacancies,
    ];

    const contextVacancyId = useContextVacancyIdFromUrl();
    const [pickedVacancyId, setPickedVacancyId] = useState(contextVacancyId);
    const [searchValue, setSearchValue] = useState('');
    const [isVacancySearchLoading, setIsVacancySearchLoading] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const { pathname, search } = useSelector((state) => state.router.location);
    const { addNotification } = useNotification();

    const stopLoading = useCallback(() => setIsVacancySearchLoading(false), []);

    const debouncedSearch = useMemo(
        () =>
            debounce((value: string) => {
                setIsVacancySearchLoading(true);
                dispatch(
                    searchManagerVacancies(
                        {
                            params: {
                                vacancyName: value,
                            },
                        },
                        addNotification
                    )
                ).then(stopLoading, stopLoading);
            }, FILTER_DEBOUNCE),
        [addNotification, dispatch, stopLoading]
    );

    const onSearchValueChange = (value: string) => {
        setSearchValue(value);
        debouncedSearch(value);
    };

    const handleSearchValueClear = () => {
        onSearchValueChange('');
    };

    const onClose = useCallback(() => {
        dispatch(showPickVacancyModal(false));
    }, [dispatch]);

    const getLinkHref = () => {
        const parsedUrl = urlParser(pathname + search);
        parsedUrl.params[CONTEXT_VACANCY_ID_PARAM] =
            pickedVacancyId === CONTEXT_VACANCY_ID_NOT_CHOSEN ? undefined : pickedVacancyId;

        return parsedUrl.href;
    };

    const onSubmit = () => {
        pickedVacancyInModalButtonClick({
            vacancyId: pickedVacancyId,
            searchInputValue: searchValue,
        });
        setIsSubmitLoading(true);
    };

    useEffect(() => {
        setSearchValue('');
        setIsSubmitLoading(false);
        onClose();
    }, [location, onClose]);

    if (!isEnablePickVacancy) {
        return null;
    }

    return (
        <Modal visible={!!isShowModal} onClose={onClose}>
            <ModalHeader>
                <ModalTitle Element="h2">{trls[TrlKeys.myVacancies]}</ModalTitle>
                <VSpacing base={3} />
                <InputText
                    placeholder={trls[TrlKeys.search]}
                    value={searchValue}
                    onChange={onSearchValueChange}
                    icon={
                        <FilterIcon
                            isLoading={isVacancySearchLoading}
                            value={searchValue}
                            handleClear={handleSearchValueClear}
                        />
                    }
                />
            </ModalHeader>
            <ModalContent>
                <Form className={style.form}>
                    {displayedVacancyList.map(({ name, vacancyId, areaName }) => (
                        <div key={vacancyId} className={style.formItem}>
                            <Radio
                                value={`${vacancyId}`}
                                checked={vacancyId === pickedVacancyId}
                                onChange={() => setPickedVacancyId(vacancyId)}
                            >
                                <Text>{name}</Text>
                                {areaName && (
                                    <Text size={TextSize.Small} importance={TextImportance.Secondary}>
                                        {areaName}
                                    </Text>
                                )}
                            </Radio>
                        </div>
                    ))}
                </Form>
            </ModalContent>
            <ModalFooter>
                <Button onClick={onClose}>{trls[TrlKeys.cancel]}</Button>
                <FormSpacer>
                    <Button
                        Element={Link}
                        to={getLinkHref()}
                        onClick={onSubmit}
                        kind={ButtonKind.Primary}
                        loading={isSubmitLoading && <Loading scale={LoadingScale.Small} initial={LoadingColor.White} />}
                    >
                        {trls[TrlKeys.submit]}
                    </Button>
                </FormSpacer>
            </ModalFooter>
        </Modal>
    );
};

export default translation(PickVacancyModal);
