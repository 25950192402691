import { FC } from 'react';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import { useSelector } from 'src/hooks/useSelector';

const ADS_POSITION_INFO = 'LAST';

const UnderSearchResultBanner: FC = () => {
    const banners = useSelector((state) => state.banners);
    const adsSearchParams = useSelector((state) => state.adsSearchParams);

    const { bannerL, bannerM, bannerS, bannerXS } = {
        bannerL: banners['under-pagination-search-results-l'],
        bannerM: banners['under-pagination-search-results-m'],
        bannerS: banners['under-pagination-search-results-s'],
        bannerXS: banners['under-pagination-search-results-xs'],
    };

    return (
        <>
            {bannerL && bannerL.length > 0 && (
                <div className="banner-place-wrapper_show-on-l">
                    <div className="center-banner" data-qa-banner-id={bannerL[0].id}>
                        <AdsExternalBanner
                            {...bannerL[0]}
                            bannerAdTargeting={adsSearchParams}
                            positionInfo={ADS_POSITION_INFO}
                        />
                    </div>
                </div>
            )}
            {bannerM && bannerM.length > 0 && (
                <div className="banner-place-wrapper_show-on-m">
                    <div className="center-banner" data-qa-banner-id={bannerM[0].id}>
                        <AdsExternalBanner
                            {...bannerM[0]}
                            bannerAdTargeting={adsSearchParams}
                            positionInfo={ADS_POSITION_INFO}
                        />
                    </div>
                </div>
            )}
            {bannerS && bannerS.length > 0 && (
                <div className="banner-place-wrapper_show-on-s">
                    <div className="center-banner" data-qa-banner-id={bannerS[0].id}>
                        <AdsExternalBanner
                            {...bannerS[0]}
                            bannerAdTargeting={adsSearchParams}
                            positionInfo={ADS_POSITION_INFO}
                        />
                    </div>
                </div>
            )}
            {bannerXS && bannerXS.length > 0 && (
                <div className="banner-place-wrapper_show-on-xs">
                    <div className="center-banner" data-qa-banner-id={bannerXS[0].id}>
                        <AdsExternalBanner
                            {...bannerXS[0]}
                            bannerAdTargeting={adsSearchParams}
                            positionInfo={ADS_POSITION_INFO}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default UnderSearchResultBanner;
