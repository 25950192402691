import { FC } from 'react';
import classnames from 'classnames';

import { Layer, LayerName, useBreakpoint } from '@hh.ru/magritte-ui';

import SearchType from 'src/components/NovaFilters/SearchType';
import ResumeSearchScrollToTop from 'src/components/SearchScrollToTop/Resume';
import VacancySearchScrollToTop from 'src/components/SearchScrollToTop/Vacancy';
import SearchPreferenceButton from 'src/components/SearchSorts/SearchPreferenceButton';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const SearchFooterButtons: FC = () => {
    const searchType = useSelector((state) => state.searchClustersSettings.type);
    const { isMobile } = useBreakpoint();

    return (
        <>
            <Layer layer={LayerName.Alpha}>
                <div className={classnames(styles.button, styles[isMobile ? 'leftButton' : 'rightButton'])}>
                    {searchType === SearchType.Vacancy && <VacancySearchScrollToTop />}
                    {searchType === SearchType.Resume && <ResumeSearchScrollToTop />}
                </div>
            </Layer>
            <Layer layer={LayerName.Alpha}>
                <div className={classnames(styles.button, styles.rightButton)}>
                    <SearchPreferenceButton />
                </div>
            </Layer>
        </>
    );
};

export default SearchFooterButtons;
