import React from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface RegistrationMessageProps {
    normalSearchResults: number;
    totalUngroupedResults: number;
    isApplicant: boolean;
    isEmployer: boolean;
}

const TrlKeys = {
    applicant: 'resumesSearch.normalResults.applicant.advanced',
    applicantTiny: 'resumesSearch.normalResults.applicant.full.tiny',
    employer: 'resumesSearch.normalResults.employer.warnRegister.v2',
};

const RegistrationMessage: TranslatedComponent<RegistrationMessageProps> = ({
    trls,
    normalSearchResults,
    totalUngroupedResults,
    isApplicant,
    isEmployer,
}) => {
    if (isApplicant) {
        if (normalSearchResults < 10) {
            return <>{trls[TrlKeys.applicantTiny]}</>;
        }
        return (
            <>
                {format(trls[TrlKeys.applicant], {
                    '{0}': numberFormatter.format(`${totalUngroupedResults}`),
                    '{1}': numberFormatter.format(`${normalSearchResults}`),
                })}
            </>
        );
    }

    if (isEmployer) {
        return (
            <>{`${format(trls[TrlKeys.employer], {
                '{0}': numberFormatter.format(normalSearchResults.toString()),
            })}${NON_BREAKING_SPACE} `}</>
        );
    }
    return null;
};

export default translation(RegistrationMessage);
