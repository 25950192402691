import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import ButtonSelect from 'bloko/blocks/button/ButtonSelect';
import ClickDown, { DownLayer, DownPlacement } from 'bloko/blocks/drop/Down/Click';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';
import urlParser from 'bloko/common/urlParser';

import SettingsForm from 'src/components/Employer/ResumeView/SettingsForm';
import ItemsOnPage from 'src/components/ResumeSearch/ItemsOnPage';
import SearchOrder from 'src/components/ResumeSearch/SearchOrder';
import SearchPeriod from 'src/components/ResumeSearch/SearchPeriod';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const Settings = ({ trls }) => {
    const activeSettings = useSelector((state) => state.resumeSearchResult.settings.activeSettings);
    const availableSettings = useSelector((state) => state.resumeSearchResult.settings.availableSettings);
    const filtersOnTheLeft = useSelector((state) => state.resumeSearchResult.settings.filtersOnTheLeft);
    const criteria = useSelector((state) => state.resumeSearchResult.criteria);
    const queries = useSelector((state) => state.resumeSearchResult.queries);
    const pathname = useSelector((state) => state.router.location.pathname);
    const showSearchPreference = useSelector((state) => state.searchPreference.isShown);
    const defaultHhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const breakpoint = getBreakpoint();

    const dispatch = useDispatch();

    const queryMap = {
        search_period: queries.searchPeriodBase, // eslint-disable-line camelcase
        order_by: queries.orderByBase, // eslint-disable-line camelcase
        items_on_page: queries.itemsOnPageBase, // eslint-disable-line camelcase
    };

    const handleChange = (name, value) => {
        const query = queryMap[name];
        const url = urlParser(pathname);
        url.search = query;
        url.params = { ...url.params, [name]: [value] };
        dispatch(push(url.href));
    };

    const ViewClickDown = useCallback(
        ({ children }) => (
            <ClickDown
                flexible
                layer={DownLayer.OverlayContent}
                placement={DownPlacement.BottomStart}
                onlySetPlacement
                render={(close) => <SettingsForm close={close} />}
            >
                {children}
            </ClickDown>
        ),
        []
    );

    const {
        search_period: searchPeriod,
        order_by: orderBy,
        items_on_page: itemsOnPage,
        date_from: dateFrom,
        date_to: dateTo,
    } = criteria;

    const genericSearchPeriod = dateFrom && dateTo ? 'customInterval' : searchPeriod;
    const selectedSettings = activeSettings.length + [filtersOnTheLeft].filter((setting) => setting?.value).length;
    return (
        <div className="resume-serp-filters resume-serp-filters_nova-order">
            <SearchPeriod
                onChange={(value) => {
                    handleChange('search_period', value);

                    const isSXS = [Breakpoint.XS, Breakpoint.S].includes(breakpoint);
                    const buttonName = `resume_search_filter_${isSXS ? 'touch' : 'apply'}`;
                    const hhtmSource = isSXS ? `resume_search_filter` : defaultHhtmSource;
                    Analytics.sendHHEventButtonClick(buttonName, {
                        type: 'SEARCH_PERIOD',
                        oldValue: genericSearchPeriod,
                        newValue: value,
                        hhtmSource,
                    });
                }}
                value={genericSearchPeriod}
            />
            <SearchOrder
                onChange={(value) => {
                    handleChange('order_by', value);
                }}
                value={orderBy}
            />
            {availableSettings.length > 0 && !showSearchPreference && (
                <ViewClickDown>
                    <ButtonSelect flexible light data-qa="resume-serp__set-view-result">
                        {trls[Settings.trls.view]}
                        {activeSettings.length > 0 && (
                            <span className="supernova-navi-counter supernova-navi-counter_clear">
                                {selectedSettings}
                            </span>
                        )}
                    </ButtonSelect>
                </ViewClickDown>
            )}
            <ItemsOnPage
                onChange={(value) => {
                    handleChange('items_on_page', value);
                }}
                value={itemsOnPage}
            />
        </div>
    );
};

Settings.propTypes = {
    trls: PropTypes.object,
};

Settings.trls = {
    advanced: 'search.advancedSearch',
    view: 'employer.resumesSearch.settings.view',
};

export default translation(Settings);
