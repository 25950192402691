import { FC } from 'react';

import GetPageHtml from 'src/components/GetPageHtml/GetPageHtml.obfuscate';
import BuyContacts from 'src/components/ResumeSearch/BuyContacts';
import QueryFixer from 'src/components/ResumeSearch/QueryFixer';
import QuerySuggests from 'src/components/ResumeSearch/QuerySuggests';
import RegistrationAction from 'src/components/ResumeSearch/RegistrationAction';

const ResumeSearchTop: FC<{ isNotVerifiedEmployerBannerExp: boolean }> = ({ isNotVerifiedEmployerBannerExp }) => {
    return (
        <>
            <BuyContacts />
            <QueryFixer />
            <QuerySuggests />
            {!isNotVerifiedEmployerBannerExp && <RegistrationAction />}
            <GetPageHtml />
        </>
    );
};

export default ResumeSearchTop;
